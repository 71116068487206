@import 'variables';
@import 'reset';

html {
  background: $primary;
}

body {
  font-family: 'Nunito', sans-serif;
  min-height: 100vh;
  color: #fff;
  background: radial-gradient(50% 50%, $primary 0%, rgba($primary5, 0.4) 100%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.js body {
  opacity: 0;
  transition: opacity 0.3s;
}

.js body.render {
  opacity: 1;
}

.js .loading::before {
  content: '';
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $primary;
}

.js .loading::after {
  content: '';
  position: fixed;
  z-index: 100000;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  pointer-events: none;
  border-radius: 50%;
  opacity: 0.4;
  background: #fff;
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

a {
  text-decoration: none;
  color: #fff;
  outline: none;
}

a:hover,
a:focus {
  color: #fff;
  outline: none;
}

.hide {
  width: 0;
  height: 0;
  display: none;
  overflow: hidden;
  pointer-events: none;
}

.hidden {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
}

.icon {
  display: block;
  width: 1.5em;
  height: 1.5em;
  margin: 0 auto;
  fill: currentColor;
}

main {
  position: relative;
  width: 100%;
}

.hologram {
  display: block;
  position: fixed;
  top: 50%;
  left: 0;
  margin-top: -150px;
  z-index: 1000;
  transform: scale(0.7);

  @media screen and (max-width: 1024px) {
    left: auto;
    top: 0;
    right: 0;
    margin-top: 0;
    transform: scale(0.4);
  }
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
}

.content--reveal {
  position: absolute;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}

.content__inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 60%;
  padding-bottom: 2em;
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: end;
  pointer-events: none;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.js .content__inner {
  opacity: 0;
  transition: opacity 0.3s;
}

.content__title {
  font-size: 8vw;
  margin: 0;
  color: #fff;
  text-transform: lowercase;
  font-weight: 700;

  @media screen and (max-height: 411px) {
    font-size: 3em;
  }

  @media screen and (max-width: 600px) {
    font-size: 3em;
  }
}

.content__subtitle {
  max-width: 500px;
  text-align: center;
  font-size: 1.25em;
  font-weight: 400;
  margin: 0;
  color: #fff;
}

.content__team {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  justify-items: center;

  & > div {
    margin: 0 1em 1em 1em;

    @media screen and (max-height: 530px) {
      margin-bottom: 0;
    }
  }

  img {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0.5em auto;

    @media screen and (max-height: 530px) {
      width: 70px;
      height: 70px;
    }
  }

}

.content__logos {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;

  img {
    display: block;
    max-width: 100%;
    width: auto;
    height: 30px;
    margin: 1em 0.5em 0 0.5em;

    @media screen and (max-width: 880px) {
      height: 25px;
    }
  }
}

.content__close {
  position: absolute;
  top: 60%;
  left: 50%;
  background: none;
  color: #fff;
  border: 2px solid #fff;
  padding: 0;
  display: block;
  margin: 0 0 0 -2em;
  width: 4em;
  height: 4em;
  font-size: 1.25em;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
  transition: all 0.15s;

  &:focus {
    outline: none;
  }

  &:hover {
    background: $primary;
    border-color: $primary;
  }
}

.scene {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;

  .blob-1 path {
    fill: url(#gradient-1);
  }

  .blob-2 path {
    fill: url(#gradient-2);
  }

  .blob-3 path {
    fill: url(#gradient-3);
  }

  .blob-4 path {
    fill: url(#gradient-4);
  }

  .blob-5 path {
    fill: url(#gradient-5);
  }

  .blob-6 path {
    fill: url(#gradient-6);
  }

  path:first-of-type {
    fill-opacity: 0.06;
  }

  path:nth-of-type(2) {
    fill-opacity: 0.13;
  }

  path:nth-of-type(3) {
    fill-opacity: 0.2;
  }

  path:nth-of-type(4) {
    fill-opacity: 0.28;
  }

  path:nth-of-type(5) {
    fill-opacity: 0.35;
  }

  path:nth-of-type(6) {
    fill-opacity: 0.45;
  }

}

.menu {
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  margin: 0 0 0 25vw;
  font-size: 4vw;
  align-items: flex-start;

  @media screen and (max-width: 1280px) {
    margin: 0 20vw 0 auto;
  }

  @media screen and (max-width: 1110px) {
    margin: 0 10vw 0 auto;
  }

  @media screen and (max-width: 1440px) and (min-height: 768px) {
    margin: 0 12vw 0 auto;
  }

  @media screen and (max-width: 880px) {
    font-size: 2.75em;
    margin: 0 auto;
    align-items: center;
  }
}

.menu__item {
  color: #fff;
  line-height: 1.25;
  text-transform: lowercase;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  user-select: none;

  &:hover,
  &:focus {
    color: #fff;
  }

  &::before {
    content: '';
    width: 60%;
    height: 10px;
    background: linear-gradient(45deg, #00b7a8, #00faac);
    position: absolute;
    left: 0;
    bottom: 12px;
    transform-origin: 0 0;
    transform: scale3d(0, 1, 1);
  }

  &:nth-child(2)::before {
    background: linear-gradient(45deg, #c7004c, #ffa45c);
  }

  &:nth-child(3)::before {
    background: linear-gradient(45deg, #74b49b, #a1dd70);
  }

  &:nth-child(4)::before {
    background: linear-gradient(45deg, #494ba2, #8086d5);
  }

  &:nth-child(5)::before {
    background: linear-gradient(45deg, #f16821, #ffcd60);
  }

  &:nth-child(6)::before {
    background: linear-gradient(45deg, #00ac53, #23c3e0);
  }

  span {
    display: block;
    position: relative;
  }
}

.menu__item--showDeco::before {
  transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
  transform: scale3d(1, 1, 1);
}

.menu__item--showDeco:hover::before {
  transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
  transform: scale3d(1.5, 1, 1);
}

.contact-form {
  width: 100%;
  max-width: 300px;
}

.input-row {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;

  input,
  textarea {
    display: block;
    width: 100%;
    padding: 5px 10px;
    outline: none;
    background: #fff;
    border-radius: 4px;
    color: $primary;
    border: 2px solid transparent;
    transition: all .15s ease-in-out;

    &:focus {
      border-color: $primary;
    }
  }

  textarea {
    height: 100px;
    resize: none;
  }

  button {
    -webkit-appearance: none;
    display: inline-block;
    outline: none;
    border-radius: 4px;
    background: transparent;
    border: 2px solid #fff;
    padding: 5px 20px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    transition: all .15s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      border-color: $primary;
      background-color: $primary;
    }
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: $secondary2;
    line-height: normal;
    opacity: 1;
  }

  input:-moz-placeholder,
  textarea:-moz-placeholder {
    color: $secondary2;
    line-height: normal;
    opacity: 1;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: $secondary2;
    line-height: normal;
    opacity: 1;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: $secondary2;
    line-height: normal;
  }

}
